<template>
  <div id="packages">
    <HeadSections
      class="packages-img"
      :TitlePage="$i18n.locale == 'ar' ? 'باقات إتمام المميزة':'Etmaam Premium Packages'"
      :TitleSec="$i18n.locale == 'ar' ? 'اختر الباقة المناسبة لاحتياجاتك أو صمّمها بنفسك ':'Choose the package that suits your needs or design your own.'"
      :PathPageFrom="$t('Home')"
      :PathPageTo="$t('Packages')"
      :pathFrom="`/`"/>
    <div class="container" style="overflow: hidden; margin-top: -180px;">
      <IndexPackages/>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Packages Page',
  components: {
    HeadSections: defineAsyncComponent(() => import('@/components/Global/HeadSections.vue')),
    IndexPackages: defineAsyncComponent(() => import('@/components/Packages/IndexPackages.vue')),
  },
  mounted() {

    this.$store.state.PackageeId = true



  },
}
</script>


